<template>
  <read-article :article="foundLgpd" />
</template>

<script>
import { externalBySlug } from '../../services/lgpd-service'

export default {
  name: 'ReadLgpd',
  data () {
    return {
      foundLgpd: {}
    }
  },
  async created () {
    this.foundLgpd = await externalBySlug(this.$route.params.slug)
  }
}
</script>

<style scoped>

</style>
