import api from './api'

const findActive = () => {
  return api.get('lgpd/active')
}

const findById = (id) => api.get(`lgpd/find-by-id/${id}`)
const findBySlug = (slug) => api.get(`lgpd/find-by-slug/${slug}`)

const findActiveExternalLgpd = () => api.get(`lgpd/active-external`);
const externalBySlug = (slug) => api.get(`lgpd/external-by-slug/${slug}`);

export { findActive, findById, findBySlug, findActiveExternalLgpd, externalBySlug };
